import { Component, OnInit } from "@angular/core";
import { environment } from "./../environments/environment";
import { NgSelectConfig } from "@ng-select/ng-select";

declare function require(moduleName: string): any;
const { version: appVersion } = require("../../package.json");

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {

	public date: Date;
	public appVersion: any;

	public options = {
		timeOut: 5000,
		preventDuplicates: true,
		position: ["right", "top"]
	};

	constructor(private config: NgSelectConfig) {
		this.config.appendTo = "body";
	}

	ngOnInit() {
		this.date = new Date();
		this.appVersion = appVersion;
		let appId: string = environment.firebase.projectId;
		if (appId === "eneza-9f10f") appId = "production";
	}

}
