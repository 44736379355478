<div class="full-page">

  <!--main contents area starts here -->
  <router-outlet></router-outlet>
  <!--main content area (except header,footer) ends -->

  <simple-notifications [options]="options"></simple-notifications>

</div>

<footer class="footer">
	<div class="col-md-4">
		<p>&copy; {{date | date:'yyyy'}} Eneza Telecom Limited.</p>
	</div>
	<div class="col-md-4">
		<p>Version {{appVersion}}</p>
	</div>
	<div class="col-md-4">
		<p>Powered by <a href="https://enezatelecom.com" target="_blank">Eneza Telecom</a>.</p>
	</div>
</footer>
