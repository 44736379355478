import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { SimpleNotificationsModule } from "angular2-notifications";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AngularFirePerformanceModule } from "@angular/fire/performance";
import { AppComponent } from "./app.component";

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AngularFirePerformanceModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireModule.initializeApp(environment.firebase),

		SimpleNotificationsModule.forRoot(),

		AppRoutingModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
