// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyBqIR-2jNdoPNNog6JUrmyqJPPmOK-loz8",
		authDomain: "eneza-dev.firebaseapp.com",
		databaseURL: "https://eneza-dev.firebaseio.com",
		projectId: "eneza-dev",
		storageBucket: "eneza-dev.appspot.com",
		messagingSenderId: "278591921298",
		appId: "1:278591921298:web:24ca9c34d512e6cd8d5a4b",
		measurementId: "G-1PV9CJ8PVH"
	},

	FB_ADMINS: "admins",
	FB_ADMINS_NEW: "admins_new",

	FB_CLIENTS: "clients",
	FB_CLIENTS_USERS: "clients_users",
	FB_USERS_UPLOADS: "users_uploads",
	FB_CLIENTS_NEW: "clients_new",
	FB_CLIENTS_REMOVED: "clients_removed",

	FB_RESELLERS: "resellers",
	FB_RESELLERS_NEW: "resellers_new",
	FB_RESELLERS_REMOVED: "resellers_removed",

	FB_USERS: "users",
	FB_USERS_REMOVED: "users_removed", // deleted
	FB_USERS_CRB_DATA: "users_crb_data",
	FB_USERS_APPRAISALS: "users_appraisals",

	FB_LOANS: "loans", // new, active, overdue, outsourced, written_off
	FB_LOANS_REPAID: "loans_repaid",
	FB_LOANS_REMOVED: "loans_removed", // cancelled, declined
	FB_LOANS_HISTORY: "loans_history",

	// FB_SUB_ITEMS: "items",
	FB_HISTORY_SUB_ITEMS: "history",
	FB_SETTINGS_SUB_ITEMS: "settings",
	FB_FLOAT_HISTORY: "float_history",
	FB_USERS_HISTORY: "users_history",

	FB_COUNTERS: "counters",
	FB_SMS_LOGS: "sms_logs",
	FB_BULK_SMS: "bulk_sms",

	FB_MESSAGES: "messages",
	FB_MESSAGES_REMOVED: "messages_removed",

	FB_CONTACTS: "contacts",
	FB_CONTACTS_GROUPS: "contacts_groups",

	// API KEYS
	FB_API_KEYS: "api_keys",

	FB_BURSARY_SCHOOLS: "bursary_schools",

	// MPESA
	FB_MPESA_BULK_TRANSACTIONS: "mpesa_bulk_transactions",
	FB_MPESA_PAYBILL_TRANSACTIONS: "mpesa_paybill_transactions",
	FB_MPESA_REVERSAL_TRANSACTIONS: "mpesa_reversal_transactions",
	FB_MPESA_STK_TRANSACTIONS: "mpesa_stk_transactions",
	FB_MPESA_DISBURSE_REQUESTS: "mpesa_disburse_requests",

	FB_CHARGES: "charges",
	FB_PURCHASES: "purchases",

	FB_SUB_ITEMS: "items",

	FB_SETTINGS: "settings",
	FB_SETTINGS_GENERAL: "general_settings",
	FB_SETTINGS_ACCOUNTING: "accounting_settings",
	FB_SETTINGS_ACCOUNTING_SALES: "sales_settings",
	FB_SETTINGS_ACCOUNTING_TAXES: "taxes",
	FB_SETTINGS_ACCOUNTING_EXPENSES_POSTING_ACCS: "expenses_posting_accounts",

	// Account types
	FB_ASSET: "asset",
	FB_EQUITY: "equity",
	FB_LIABILITIES: "liabilities",
	FB_REVENUE: "revenue",
	FB_EXPENSES: "expenses",

	// Accounting module
	FB_FINANCIAL_ACCOUNTS: "financial_accounts",
	FB_ACCOUNTS: "accounts",
	FB_ACCOUNTING_JOURNALS: "financial_journals",
	FB_JOURNALS: "journals",
	FB_ACCOUNT_ENTRIES: "journal_entries", // In the process of Deprecation
	FB_JOURNAL_ENTRIES: "financial_journals_entries",
	FB_AUTOPOSTING_ACTIONS: "autoposting_journal_actions",
	FB_INVOICES: "invoices",
	FB_CREDIT_NOTES: "credit_notes",
	FB_ACCOUNTING_AUDIT_LOGS: "accounting_audit_logs",
	FB_ONBOARDING_CHART_OF_ACCOUNTS: "onboarding_chart_of_accounts",
	FB_CUSTOMERS: "customers",
	FB_STOCK_ADJUSTMENT: "stock_adjustment",
	FB_PRODUCTS_AND_SERVICES: "products_and_services",

	// Client history sub collections
	HISTORY_MANUAL_EDITS: "manual_edits",
	HISTORY_MANUAL_FLOAT_EDITS: "manual_float_edits",
	HISTORY_USER_ACTIVATIONS: "user_activations",
	HISTORY_REF_USER_ACTIVATIONS: "ref_user_activations",
	HISTORY_LOANS_DISBURSED: "loans_disbursed",
	HISTORY_LOANS_DISBURSED_FEES: "loans_disbursed_fees",
	HISTORY_LOANS_REPAID: "loans_repaid",
	HISTORY_LOANS_REMOVED: "loans_removed",
	HISTORY_REF_LOANS_REPAID: "ref_loans_repaid",
	HISTORY_FLOAT_WITHDRAWALS: "float_withdrawals",
	HISTORY_FLOAT_WITHDRAWALS_FEES: "float_withdrawals_fees",
	HISTORY_LOANS_PROCESSING_FEE_REPAID: "loans_processing_fee_repaid",
	HISTORY_LOANS_RENEWAL_FEE_REPAID: "loans_renewal_fee_repaid",
	HISTORY_LOANS_PRINCIPAL_REPAID: "loans_principal_repaid",
	HISTORY_LOANS_INTEREST_REPAID: "loans_interest_repaid",
	HISTORY_LOANS_ROLLOVER_REPAID: "loans_rollover_repaid",
	HISTORY_MPESA_DEPOSITS: "mpesa_deposits",

	// Loan status
	LOAN_STATUS_PENDING: "pending",
	LOAN_STATUS_DECLINED: "declined",
	LOAN_STATUS_APPROVED: "approved",
	LOAN_STATUS_DISBURSED: "disbursed",
	LOAN_STATUS_OVERDUE: "overdue",
	LOAN_STATUS_OUTSOURCED: "outsourced",
	LOAN_STATUS_REPAID: "repaid",
	LOAN_STATUS_REPAID_PARTIAL: "repaid_partial",
	LOAN_STATUS_CANCELLED: "cancelled",
	LOAN_STATUS_WRITTEN_OFF: "writtenOff",

	// Message status
	MESSAGE_STATUS_QUEUED: "queued",
	MESSAGE_STATUS_SENT: "sent",
	MESSAGE_STATUS_FAILED: "failed",
	MESSAGE_STATUS_PROCESSING: "processing",
	MESSAGE_STATUS_COMPLETED: "completed",
	MESSAGE_STATUS_CANCELLED: "cancelled",

	TRANSATION_TYPE_TILL: "till",
	TRANSATION_TYPE_PAYBILL: "paybill",

	TRANSACTION_STATUS_INITIATED: "initiated",
	TRANSACTION_STATUS_CONFIRMED: "confirmed",
	TRANSACTION_STATUS_PROCESSING: "processing",
	TRANSACTION_STATUS_DISBURSED: "disbursed",
	TRANSACTION_STATUS_CANCELLED: "cancelled",
	TRANSACTION_STATUS_SUCCESSFUL: "successful", // bulk & paybill
	TRANSACTION_STATUS_FAILED: "failed", // bulk & paybill

	ENEZA_SMS_UNIT_PRICE: 0.3, // KES
	DEFAULT_CURRENCY: "KES",
	DEFAULT_SMS_SENDER_ID: "ENEZA",
	DEFAULT_RESELLER_CODE: "ENEZA",

	FUNCTIONS_URL: "https://us-central1-eneza-dev.cloudfunctions.net",
};
