import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
	{ path: "", redirectTo: "auth/login", pathMatch: "full" },
	{ path: "auth", loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule) },
	{ path: "home", loadChildren: () => import("./home/home.module").then(m => m.HomeModule) },
	{ path: "**", redirectTo: "auth/notfound", pathMatch: "full" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
